@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

.article {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: calc(var(--radius-main) * 3);
  height: 100%;
  box-shadow: 18px 15px 35px 0 rgba(0, 0, 0, 0.09);

  &__content {
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    height: 100%;
    background-color: var(--color-white);
  }

  h3 {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    width: 100%;
    min-height: 63px;
    max-width: 245px;
    font-size: clamp(20px, 1.0833rem + 0.7407vi, 28px);
    line-height: 1.1;
  }

  p {
    margin-bottom: 17px;
    font-size: 16px;
  }

  a {
    margin-top: auto;
    width: fit-content;
    font-size: 16px;
    letter-spacing: 0.025em;
    text-decoration: underline;
    text-decoration-color: transparent;
    color: var(--color-green-400);
    transition: text-decoration-color 0.4s linear;

    &:hover {
      text-decoration-color: var(--color-green-400);
    }
  }

  img {
    border-radius: 24px 24px 0 0;
    width: 100%;
  }

  @include media(tablet) {
    &__content {
      padding: 16px;
      height: fit-content;
    }

    h3 {
      min-height: 35px;
      max-width: 100%;
    }
  }

  @include media(mobile-m) {
    img {
      aspect-ratio: 16/9;
    }
  }
}
