@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.filter {
  $root: &;

  &__desc {
    margin: 0 auto rem(49);
    width: 100%;
    max-width: 700px;
    text-align: center;
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto rem(48);
    border-radius: 100px;
    width: 100%;
    max-width: 1180px;
    background-color: rgba(4, 58, 42, 0.12);
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: rem(48);
    gap: 24px;
  }

  &--main {
    padding: rem(85) 0;

    #{$root}__grid {
      margin-bottom: 0;
    }
  }

  @include media(tablet-wide) {
    &__grid {
      grid-template-columns: repeat(3, 1fr);
    }

    &__nav {
      max-width: 100%;
      justify-content: flex-start;
    }
  }

  @include media(tablet) {
    &__nav {
      margin-bottom: 30px;
    }

    &__grid {
      grid-template-columns: repeat(2, 1fr);
    }

    &__desc {
      margin-bottom: 25px;
    }

    &--main {
      padding: 40px 0;
    }
  }

  @include media(mobile-m) {
    &__grid {
      grid-template-columns: 1fr;
    }
  }
}
