@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.header {
  position: fixed;
  top: 0;
  z-index: 10;
  padding: 31px 0;
  width: 100%;
  background-color: var(--color-green-900);
  transition-property: transform;
  transition-duration: 0.15s;

  &.hide {
    transform: translateY(-200%);
  }

  @include media(tablet) {
    padding: 16px 0;

    .nav__inner {
      position: fixed;
      right: 0;
      top: 0;
      z-index: -1;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: auto;
      padding-top: calc(var(--header-height) * 1.3);
      width: 100%;
      height: var(--vh);
      max-width: 350px;
      background-color: var(--color-green-900);
      transform: translateX(150%);
      transition-property: transform;
      transition-duration: 0.4s;
    }

    .nav__list {
      z-index: -1;
      flex-direction: column;
    }

    .nav__item {
      margin-bottom: 30px;

      &:not(:last-child) {
        margin-right: 0;
      }
    }

    .nav__link {
      font-size: 20px;
    }
  }
}
