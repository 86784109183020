@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.initiatives {
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    img {
      overflow: hidden;
      border-radius: calc(var(--radius-main) * 8);
    }

    &:not(:last-child) {
      padding-bottom: rem(86);
    }

    &:last-child {
      padding-top: rem(86);
    }

    &--reverse {
      flex-direction: row-reverse;
      padding-top: rem(86);
    }
  }

  &__content {
    width: 100%;
    max-width: 610px;

    p {
      &:first-of-type {
        margin-bottom: 27px;
      }
    }
  }

  @include media(tablet) {
    &__item {
      flex-direction: column;

      img {
        width: 100%;
        aspect-ratio: 16/9;
      }

      &:not(:last-child) {
        padding-bottom: 20px;
      }

      &:last-child {
        padding-top: 20px;
      }

      &--reverse {
        padding-top: 20px;
      }
    }

    &__content {
      max-width: 100%;
    }
  }
}
