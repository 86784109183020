@import "https://fonts.googleapis.com/css2?family=Crimson+Pro:ital,wght@0,200..900;1,200..900&family=Noto+Sans+Display:ital,wght@0,100..900;1,100..900&display=swap";
:root {
  --content-width: 1244px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Noto Sans Display", sans-serif;
  --font-family-secondary: "Crimson Pro", sans-serif;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --color-white: #fff;
  --color-black: #000;
  --color-grey-10: #f5f6f5;
  --color-green-400: #2fb945;
  --color-green-900: #043a2a;
  --radius-main: 8px;
  --gradient-green: #043a2a7a;
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  letter-spacing: 0;
  color: var(--color-green-900);
  background-color: var(--color-white);
  font-optical-sizing: auto;
  scroll-padding: var(--header-height);
  font-size: clamp(16px, 1vw, 20px);
  font-style: normal;
  line-height: 1.6;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
  position: relative;
}

.page__body:before {
  content: "";
  z-index: -1;
  opacity: 0;
  background-color: #27282bbf;
  transition: opacity .3s;
  position: absolute;
  inset: 0;
}

.page.open {
  overflow: hidden;
}

.page.open > .page__body:before {
  z-index: 5;
  opacity: 1;
}

.section, .background {
  padding: 5.375rem 0;
}

@media only screen and (max-width: 992px) {
  .section, .background {
    padding: 2.5rem 0;
  }
}

.background {
  background-color: var(--color-grey-10);
}

.container {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.main {
  padding-top: calc(var(--header-height)  - 1px);
}

.logo {
  width: 100%;
  max-width: 261px;
  margin-right: 4.25rem;
}

@media only screen and (max-width: 1180px) {
  .logo {
    max-width: 220px;
  }
}

.is-scrolling {
  scrollbar-color: var(--color-green-900) var(--color-white);
  scrollbar-width: thin;
}

.is-scrolling::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.is-scrolling::-webkit-scrollbar-track {
  background-color: var(--color-white);
  border-radius: 10px;
}

.is-scrolling::-webkit-scrollbar-thumb {
  background-color: var(--color-green-900);
  border-radius: 10px;
}

@media only screen and (max-width: 1180px) {
  .is-scrolling {
    padding: 15px;
    overflow-y: scroll;
  }
}

.hidden {
  opacity: 0;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible:focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3 {
  font-family: var(--font-family-secondary);
  font-weight: var(--fw-500);
  color: var(--color-green-900);
  margin-bottom: 32px;
  font-size: clamp(35px, 1.4583rem + 3.2407vi, 70px);
  line-height: 1.1;
  overflow: hidden;
}

h1 span, h2 span, h3 span {
  color: var(--color-green-400);
  display: inline-block;
}

h2 {
  margin-bottom: 24px;
}

h3 {
  font-size: clamp(18px, 1.0833rem + .7407vi, 18px);
}

p {
  letter-spacing: .026em;
  font-size: 18px;
}

@media only screen and (max-width: 576px) {
  p {
    font-size: 16px;
  }
}

.header {
  z-index: 10;
  width: 100%;
  background-color: var(--color-green-900);
  padding: 31px 0;
  transition-property: transform;
  transition-duration: .15s;
  position: fixed;
  top: 0;
}

.header.hide {
  transform: translateY(-200%);
}

@media only screen and (max-width: 992px) {
  .header {
    padding: 16px 0;
  }

  .header .nav__inner {
    z-index: -1;
    padding-top: calc(var(--header-height) * 1.3);
    width: 100%;
    height: var(--vh);
    max-width: 350px;
    background-color: var(--color-green-900);
    flex-direction: column;
    justify-content: flex-start;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    top: 0;
    right: 0;
    overflow-y: auto;
    transform: translateX(150%);
  }

  .header .nav__list {
    z-index: -1;
    flex-direction: column;
  }

  .header .nav__item {
    margin-bottom: 30px;
  }

  .header .nav__item:not(:last-child) {
    margin-right: 0;
  }

  .header .nav__link {
    font-size: 20px;
  }
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav__inner {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.nav__list {
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.nav__item:not(:last-of-type) {
  margin-right: 33px;
}

@media only screen and (max-width: 1180px) {
  .nav__item:not(:last-of-type) {
    margin-right: 15px;
  }
}

.nav__link {
  color: var(--color-white);
  font-family: var(--font-family-secondary);
  background-color: #0000;
  font-size: 18px;
  line-height: 1.33;
  transition: color .3s linear;
}

.nav__link:hover, .nav__link.true {
  color: var(--color-green-400);
}

.nav__btns {
  align-items: center;
  gap: 14px;
  display: flex;
}

.nav--footer {
  margin-bottom: 28px;
}

.nav--footer .nav__list {
  margin: 0;
}

@media only screen and (max-width: 576px) {
  .nav--footer .nav__item {
    margin: 0 0 16px;
  }
}

@media only screen and (max-width: 992px) {
  .nav--footer {
    flex-direction: column;
    gap: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .nav--footer .nav__link {
    font-size: 16px;
  }
}

@media only screen and (max-width: 576px) {
  .nav--footer .nav__list {
    flex-direction: column;
  }
}

[class].btn {
  width: 100%;
  height: max-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-weight: var(--fw-500);
  color: var(--color-white);
  background-color: var(--color-green-400);
  border: 1px solid #0000;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  font-size: 18px;
  line-height: 1.33;
  transition-property: background-color, color, border-color;
  transition-duration: .3s;
  display: flex;
}

[class].btn:hover, [class].btn:focus {
  border-color: var(--color-green-900);
  color: var(--color-green-900);
  background-color: var(--color-white);
}

[class].btn.formsapp-button {
  margin: 0;
  font-family: var(--font-family-primary) !important;
}

[class].btn--reverse {
  color: var(--color-green-900);
  background-color: var(--color-white);
}

[class].btn--reverse:hover, [class].btn--reverse:focus {
  color: var(--color-white);
  background-color: var(--color-green-400);
}

[class].btn--filter {
  white-space: nowrap;
  color: var(--color-green-900);
  background-color: #0000;
  padding: 11px 23px;
  font-size: 16px;
  line-height: 1.5;
}

[class].btn--filter:hover, [class].btn--filter:focus, [class].btn--filter.active {
  color: var(--color-white);
  background-color: var(--color-green-400);
  border-color: #0000;
}

[class].btn--more {
  color: var(--color-green-900);
  background-color: #2fb9453d;
  gap: 8px;
  margin-inline: auto;
}

[class].btn--more:after {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("../images/icons/down.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

[class].btn--request {
  color: var(--color-white) !important;
  background-color: var(--color-green-400) !important;
}

[class].btn--request:hover, [class].btn--request:focus {
  border-color: var(--color-green-900);
  color: var(--color-green-900) !important;
  background-color: var(--color-white) !important;
}

.burger {
  --line-height: 2px;
  --burger-size: 20px;
  --move: calc(var(--burger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-white);
  width: calc(var(--burger-size)  + 5px);
  height: var(--burger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.policy {
  letter-spacing: .02em;
}

.policy__content:not(:last-child) {
  margin-bottom: 3rem;
}

.policy h1 {
  margin-bottom: 3.75rem;
}

.policy h2 {
  margin-bottom: 24px;
  font-size: clamp(24px, 1rem + 2.2222vi, 48px);
}

.policy ul {
  margin-bottom: 20px;
  padding-left: 15px;
}

.policy li {
  letter-spacing: .02em;
  padding-left: 10px;
}

.policy li:not(:last-child) {
  margin-bottom: 3px;
}

.policy li::marker {
  content: "✓";
  color: var(--color-green-900);
}

.policy p {
  line-height: 1.2;
}

.policy p:not(:last-child) {
  margin-bottom: 20px;
}

.policy a {
  color: var(--color-green-900);
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .policy__content:not(:last-child) {
    margin-bottom: 24px;
  }
}

.footer {
  background-color: var(--color-green-900);
  padding: 2.6875rem 0 31px;
}

.footer__bottom {
  font-family: var(--font-family-secondary);
  text-align: center;
  color: var(--color-green-400);
}

.footer__bottom p {
  letter-spacing: 0;
  line-height: 1.33;
}

.footer .logo {
  margin-right: 16px;
}

@media only screen and (max-width: 768px) {
  .footer .logo {
    margin-right: 0;
  }
}

.contacts {
  padding: 5.375rem 0;
}

.contacts__wrapper {
  border-radius: calc(var(--radius-main) * 8);
  min-height: 500px;
  background-color: var(--color-green-900);
  flex-direction: column;
  justify-content: center;
  padding: 0 6.625rem;
  display: flex;
  position: relative;
}

.contacts__content {
  z-index: 2;
  width: 100%;
  max-width: 715px;
}

.contacts img {
  pointer-events: none;
  position: absolute;
  bottom: 0;
  right: 70px;
}

.contacts__inner {
  border-radius: calc(var(--radius-main) * 3);
  width: 100%;
  max-width: 596px;
  color: var(--color-grey-400);
  -webkit-backdrop-filter: blur(66px);
  backdrop-filter: blur(66px);
  background: linear-gradient(90deg, #fefefeb3 0%, #daf6cab3 100%);
  gap: 24px;
  padding: 24px;
  position: absolute;
  bottom: 0;
  right: 0;
  box-shadow: 106px -86px 100px #2771a024;
}

.contacts__form {
  width: 100%;
  max-width: 612px;
  justify-content: space-between;
  gap: 24px;
  display: flex;
}

.contacts__form label {
  width: 100%;
}

.contacts__form input {
  width: 100%;
  max-width: 100%;
  color: var(--color-white);
  background-color: #ffffff14;
  border-radius: 16px;
  padding: 16px 24px;
}

.contacts__form input::placeholder {
  color: var(--color-white);
  line-height: 1.33;
}

.contacts h2 {
  color: var(--color-white);
  margin-bottom: 3rem;
}

@media only screen and (max-width: 992px) {
  .contacts {
    padding-bottom: 40px;
  }

  .contacts__wrapper {
    padding: 24px;
  }

  .contacts img {
    right: 0;
  }

  .contacts input {
    background-color: var(--color-green-400);
  }
}

@media only screen and (max-width: 768px) {
  .contacts {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 576px) {
  .contacts__form {
    flex-direction: column;
  }

  .contacts__form .btn {
    max-width: 100%;
  }
}

@media only screen and (max-width: 375px) {
  .contacts {
    padding-top: 10px;
  }
}

.hero {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero__inner {
  align-items: center;
  padding-right: max(16px, 50% - 622px);
  display: flex;
}

.hero__image {
  width: calc(100% - 740px);
  align-self: flex-start;
  margin-right: 8.125rem;
  position: relative;
  overflow: hidden;
}

.hero__image:before {
  content: "";
  z-index: 1;
  background-color: var(--gradient-green);
  position: absolute;
  inset: 0;
}

.hero__image img {
  width: 100%;
}

.hero__content {
  width: 100%;
  max-width: 610px;
  flex-shrink: 0;
}

.hero__content p {
  margin-bottom: 32px;
}

.hero__wrapper {
  z-index: 1;
  min-height: 780px;
  color: var(--color-white);
  flex-direction: column;
  justify-content: center;
  padding: 3.75rem 0;
  display: flex;
  position: relative;
}

.hero__wrapper:before {
  content: "";
  z-index: -1;
  background-color: var(--gradient-green);
  position: absolute;
  inset: 0;
}

.hero__wrapper h1 {
  color: var(--color-white);
}

.hero__wrapper span {
  display: inline;
}

.hero--reverse .hero__inner {
  padding-left: max(16px, 50% - 622px);
  padding-right: 0;
}

.hero--reverse .hero__content {
  margin-right: 8.125rem;
  translate: 0 15px;
}

.hero--reverse .hero__content span {
  display: inline;
}

.hero--reverse .hero__image {
  width: calc(100% - 740px);
  margin-right: 0;
}

.hero--overlay .hero__content {
  translate: 0 16px;
}

.hero--policy .hero__wrapper {
  min-height: 400px;
}

.hero--policy .hero__wrapper h1 {
  margin-bottom: 0;
}

@media only screen and (max-width: 1180px) {
  .hero__image {
    margin-right: 60px;
  }
}

@media only screen and (max-width: 992px) {
  .hero__wrapper {
    min-height: 350px;
    padding: 0;
  }

  .hero__inner {
    flex-direction: column-reverse;
    padding: 0 16px !important;
  }

  .hero--reverse .hero__inner {
    flex-direction: column;
  }

  .hero--reverse .hero__content {
    margin: 0 auto;
    padding: 16px;
    translate: 0;
  }

  .hero--reverse .hero__image {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }

  .hero__content {
    max-width: 100%;
    padding: 16px;
    translate: 0;
  }

  .hero__image {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }

  .hero__image img {
    aspect-ratio: 12 / 9;
    object-position: center;
  }
}

@media only screen and (max-width: 576px) {
  .hero .btn {
    max-width: 100%;
  }
}

.choose__wrapper {
  justify-content: space-between;
  gap: 16px;
  display: flex;
}

.choose__inner {
  width: 100%;
  max-width: 610px;
}

.choose__content {
  margin-bottom: 32px;
}

.choose__list {
  gap: 25px;
  display: grid;
}

.choose__item {
  justify-content: space-between;
  gap: 24px;
  display: flex;
}

.choose__item:before {
  content: "";
  width: 80px;
  height: 80px;
  aspect-ratio: 1 / 1;
  background-image: url("../images/icons/sourced.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.choose__item h3 {
  margin-bottom: 12px;
  font-size: clamp(20px, 1.0833rem + .7407vi, 28px);
}

.choose__item p {
  font-size: 16px;
}

.choose__item--sourced:before {
  background-image: url("../images/icons/sourced.svg");
}

.choose__item--organic:before {
  background-image: url("../images/icons/organic.svg");
}

.choose__item--practices:before {
  background-image: url("../images/icons/practices.svg");
}

.choose__item--support:before {
  background-image: url("../images/icons/support.svg");
}

.choose__item--heart:before {
  background-image: url("../images/icons/heart.svg");
}

.choose__item--transparency:before {
  background-image: url("../images/icons/transparency.svg");
}

.choose__item--eco:before {
  background-image: url("../images/icons/eco.svg");
}

.choose__item--packaging:before {
  background-image: url("../images/icons/packaging.svg");
}

.choose__image {
  border-radius: calc(var(--radius-main) * 3);
  overflow: hidden;
}

.choose h2 span {
  display: inline;
}

.choose p {
  letter-spacing: .03em;
}

@media only screen and (max-width: 992px) {
  .choose__wrapper {
    flex-direction: column;
    justify-content: center;
  }

  .choose__inner {
    max-width: 100%;
  }

  .choose__list {
    grid-template-columns: repeat(2, 1fr);
    gap: 22px;
  }

  .choose__image img {
    width: 100%;
    object-position: center;
    aspect-ratio: 16 / 9;
  }

  .choose__item {
    flex-direction: column;
  }

  .choose__item:before {
    width: 50px;
    height: 50px;
    background-size: contain;
  }

  .choose__item:last-child {
    grid-column: 2 span;
  }
}

@media only screen and (max-width: 576px) {
  .choose__list {
    grid-template-columns: 1fr;
  }

  .choose__item:last-child {
    grid-column: auto;
  }
}

.filter__desc {
  width: 100%;
  max-width: 700px;
  text-align: center;
  margin: 0 auto 3.0625rem;
}

.filter__nav {
  width: 100%;
  max-width: 1180px;
  background-color: #043a2a1f;
  border-radius: 100px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 3rem;
  display: flex;
}

.filter__grid {
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  margin-bottom: 3rem;
  display: grid;
}

.filter--main {
  padding: 5.3125rem 0;
}

.filter--main .filter__grid {
  margin-bottom: 0;
}

@media only screen and (max-width: 1180px) {
  .filter__grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .filter__nav {
    max-width: 100%;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 992px) {
  .filter__nav {
    margin-bottom: 30px;
  }

  .filter__grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .filter__desc {
    margin-bottom: 25px;
  }

  .filter--main {
    padding: 40px 0;
  }
}

@media only screen and (max-width: 576px) {
  .filter__grid {
    grid-template-columns: 1fr;
  }
}

.article {
  border-radius: calc(var(--radius-main) * 3);
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow: hidden;
  box-shadow: 18px 15px 35px #00000017;
}

.article__content {
  height: 100%;
  background-color: var(--color-white);
  flex-direction: column;
  padding: 24px 16px;
  display: flex;
}

.article h3 {
  width: 100%;
  min-height: 63px;
  max-width: 245px;
  align-items: center;
  margin-bottom: 16px;
  font-size: clamp(20px, 1.0833rem + .7407vi, 28px);
  line-height: 1.1;
  display: flex;
}

.article p {
  margin-bottom: 17px;
  font-size: 16px;
}

.article a {
  width: -moz-fit-content;
  width: fit-content;
  letter-spacing: .025em;
  color: var(--color-green-400);
  margin-top: auto;
  font-size: 16px;
  -webkit-text-decoration: underline #0000;
  text-decoration: underline #0000;
  transition: -webkit-text-decoration-color .4s linear, text-decoration-color .4s linear;
}

.article a:hover {
  -webkit-text-decoration-color: var(--color-green-400);
  text-decoration-color: var(--color-green-400);
}

.article img {
  width: 100%;
  border-radius: 24px 24px 0 0;
}

@media only screen and (max-width: 992px) {
  .article__content {
    height: -moz-fit-content;
    height: fit-content;
    padding: 16px;
  }

  .article h3 {
    min-height: 35px;
    max-width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .article img {
    aspect-ratio: 16 / 9;
  }
}

.single-product {
  padding: 4.375rem 0;
}

.single-product__inner {
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  display: flex;
}

.single-product__content {
  width: 100%;
  max-width: 610px;
}

.single-product__link {
  font-weight: var(--fw-500);
  letter-spacing: .02em;
  color: var(--color-green-400);
  align-items: center;
  gap: 10px;
  margin-bottom: 24px;
  font-size: 18px;
  -webkit-text-decoration: underline #0000;
  text-decoration: underline #0000;
  transition: -webkit-text-decoration-color .4s linear, text-decoration-color .4s linear;
  display: flex;
}

.single-product__link:before {
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("../images/icons/arrow.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.single-product__link:hover {
  -webkit-text-decoration-color: var(--color-green-400);
  text-decoration-color: var(--color-green-400);
}

.single-product__desc h2 {
  color: var(--color-green-400);
  margin-bottom: 24px;
  font-size: clamp(20px, 1rem + 1.1111vi, 32px);
}

.single-product__list li {
  letter-spacing: .025em;
  font-size: 18px;
}

.single-product__list li span {
  color: var(--color-green-400);
}

.single-product__list li:not(:last-child), .single-product h1, .single-product p {
  margin-bottom: 24px;
}

.single-product img {
  border-radius: calc(var(--radius-main) * 3);
  overflow: hidden;
}

@media only screen and (max-width: 992px) {
  .single-product {
    padding: 35px 0;
  }

  .single-product__inner {
    flex-direction: column-reverse;
  }

  .single-product__content {
    max-width: 100%;
  }

  .single-product img {
    width: 100%;
    aspect-ratio: 16 / 9;
  }
}

.journey__content {
  width: 100%;
  max-width: 745px;
  text-align: center;
  margin: 0 auto 3rem;
}

.journey__image {
  border-radius: calc(var(--radius-main) * 8);
  margin-bottom: 2.8125rem;
  overflow: hidden;
}

.journey .btn {
  margin: 0 auto;
}

.initiatives__item {
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  display: flex;
}

.initiatives__item img {
  border-radius: calc(var(--radius-main) * 8);
  overflow: hidden;
}

.initiatives__item:not(:last-child) {
  padding-bottom: 5.375rem;
}

.initiatives__item:last-child {
  padding-top: 5.375rem;
}

.initiatives__item--reverse {
  flex-direction: row-reverse;
  padding-top: 5.375rem;
}

.initiatives__content {
  width: 100%;
  max-width: 610px;
}

.initiatives__content p:first-of-type {
  margin-bottom: 27px;
}

@media only screen and (max-width: 992px) {
  .initiatives__item {
    flex-direction: column;
  }

  .initiatives__item img {
    width: 100%;
    aspect-ratio: 16 / 9;
  }

  .initiatives__item:not(:last-child) {
    padding-bottom: 20px;
  }

  .initiatives__item:last-child, .initiatives__item--reverse {
    padding-top: 20px;
  }

  .initiatives__content {
    max-width: 100%;
  }
}

.story__content {
  width: 100%;
  max-width: 822px;
  text-align: center;
  margin: 0 auto 3rem;
}

.story__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  display: grid;
}

.story__item {
  height: 100%;
  gap: 2.625rem;
  display: grid;
}

.story__block {
  border-radius: calc(var(--radius-main) * 4);
  min-height: 388px;
  overflow: hidden;
}

.story__block h3 {
  color: var(--color-white);
  margin-bottom: 16px;
  font-size: clamp(20px, 1rem + 1.1111vi, 32px);
}

.story__block p {
  letter-spacing: .03em;
  text-align: center;
  font-size: 16px;
}

.story__block img {
  width: 100%;
  height: 100%;
}

.story__block--colored {
  color: var(--color-white);
  background-color: var(--color-green-400);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  display: flex;
}

.story h2 span {
  display: inline;
}

@media only screen and (max-width: 1180px) {
  .story__block--colored {
    padding: 24px;
  }
}

@media only screen and (max-width: 992px) {
  .story__list {
    grid-template-columns: 1fr;
    gap: 24px;
  }

  .story__item {
    grid-template-columns: repeat(2, 1fr);
  }

  .story__block {
    min-height: max-content;
  }

  .story__block img {
    object-position: center;
    aspect-ratio: 16 / 9;
  }

  .story__block--colored {
    padding: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .story__list {
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }

  .story__item {
    grid-template-columns: repeat(1, 1fr);
  }

  .story__block--colored {
    grid-row: 1 / span 1;
    padding: 16px;
  }
}

.farmers__content {
  width: 100%;
  max-width: 745px;
  text-align: center;
  margin: 0 auto 5rem;
}

.farmers__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  display: grid;
}

.farmers__item {
  border-radius: calc(var(--radius-main) * 3);
  text-align: center;
  background-color: var(--color-white);
  place-items: center;
  padding: 3.5rem 35px;
  display: grid;
  box-shadow: 18px 15px 35px #00000017;
}

.farmers__item h3 {
  margin-bottom: 27px;
  font-size: clamp(20px, 1rem + 1.1111vi, 32px);
  line-height: 1.1;
}

.farmers__item p {
  text-align: center;
  font-size: 16px;
}

.farmers__item img {
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  margin-bottom: 24px;
}

@media only screen and (max-width: 768px) {
  .farmers__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .farmers__item {
    padding: 24px;
  }

  .farmers__item:last-child {
    grid-column: 2 span;
  }
}

@media only screen and (max-width: 576px) {
  .farmers__list {
    grid-template-columns: 1fr;
  }

  .farmers__item:last-child {
    grid-column: auto;
  }
}

/*# sourceMappingURL=main.css.map */
