/* stylelint-disable declaration-no-important */
@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

*[class].btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: 100px;
  padding: 16px 32px;
  width: 100%;
  height: max-content;
  max-width: fit-content;
  font-weight: var(--fw-500);
  font-size: 18px;
  line-height: 1.33;
  color: var(--color-white);
  background-color: var(--color-green-400);
  transition-property: background-color, color, border-color;
  transition-duration: 0.3s;

  &:hover,
  &:focus {
    border-color: var(--color-green-900);
    color: var(--color-green-900);
    background-color: var(--color-white);
  }

  &.formsapp-button {
    margin: 0;
    font-family: var(--font-family-primary) !important;
  }

  &--reverse {
    color: var(--color-green-900);
    background-color: var(--color-white);

    &:hover,
    &:focus {
      color: var(--color-white);
      background-color: var(--color-green-400);
    }
  }

  &--filter {
    padding: 11px 23px;
    font-size: 16px;
    line-height: 1.5;
    white-space: nowrap;
    color: var(--color-green-900);
    background-color: transparent;

    &:hover,
    &:focus {
      border-color: transparent;
      color: var(--color-white);
      background-color: var(--color-green-400);
    }

    &.active {
      border-color: transparent;
      color: var(--color-white);
      background-color: var(--color-green-400);
    }
  }

  &--more {
    color: var(--color-green-900);
    background-color: rgba(47, 185, 69, 0.24);
    gap: 8px;
    margin-inline: auto;

    &::after {
      content: "";
      position: relative;
      width: 24px;
      height: 24px;
      background-image: url("../images/icons/down.svg");
      background-position: 0 0;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  &--request {
    color: var(--color-white) !important;
    background-color: var(--color-green-400) !important;

    &:hover,
    &:focus {
      border-color: var(--color-green-900);
      color: var(--color-green-900) !important;
      background-color: var(--color-white) !important;
    }
  }
}
