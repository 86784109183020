@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.story {
  &__content {
    margin: 0 auto rem(48);
    width: 100%;
    max-width: 822px;
    text-align: center;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: rem(40);
  }

  &__item {
    display: grid;
    height: 100%;
    gap: rem(42);
  }

  &__block {
    overflow: hidden;
    border-radius: calc(var(--radius-main) * 4);
    min-height: 388px;

    h3 {
      margin-bottom: 16px;
      font-size: clamp(20px, 1rem + 1.1111vi, 32px);
      color: var(--color-white);
    }

    p {
      font-size: 16px;
      letter-spacing: 0.03em;
      text-align: center;
    }

    img {
      width: 100%;
      height: 100%;
    }

    &--colored {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 32px;
      color: var(--color-white);
      background-color: var(--color-green-400);
    }
  }

  h2 {
    span {
      display: inline;
    }
  }

  @include media(tablet-wide) {
    &__block {
      &--colored {
        padding: 24px;
      }
    }
  }

  @include media(tablet) {
    &__list {
      grid-template-columns: 1fr;
      gap: 24px;
    }

    &__item {
      grid-template-columns: repeat(2, 1fr);
    }

    &__block {
      min-height: max-content;

      img {
        object-position: center;
        aspect-ratio: 16/9;
      }

      &--colored {
        padding: 24px;
      }
    }
  }

  @include media(mobile-l) {
    &__list {
      align-items: center;
      justify-content: center;
      grid-template-columns: 1fr;
    }

    &__item {
      grid-template-columns: repeat(1, 1fr);

    }

    &__block {
      &--colored {
        padding: 16px;
        grid-row: 1 / span 1;
      }
    }
  }
}
