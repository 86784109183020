@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.farmers {
  &__content {
    margin: 0 auto rem(80);
    width: 100%;
    max-width: 745px;
    text-align: center;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }

  &__item {
    display: grid;
    place-items: center;
    border-radius: calc(var(--radius-main) * 3);
    padding: rem(56) 35px;
    box-shadow: 18px 15px 35px 0 rgba(0, 0, 0, 0.09);
    text-align: center;
    background-color: var(--color-white);

    h3 {
      margin-bottom: 27px;
      font-size: clamp(20px, 1rem + 1.1111vi, 32px);
      line-height: 1.1;
    }

    p {
      font-size: 16px;
      text-align: center;
    }

    img {
      margin-bottom: 24px;
      border-radius: 50%;
      aspect-ratio: 1/1;
    }
  }

  @include media(mobile-l) {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      padding: 24px;

      &:last-child {
        grid-column: 2 span;
      }
    }
  }

  @include media(mobile-m) {
    &__list {
      grid-template-columns: 1fr;
    }

    &__item {
      &:last-child {
        grid-column: auto;
      }
    }
  }
}
